import * as React from "react"

import {renderRichText} from "gatsby-source-contentful/rich-text";

const FullPageContentSection = (props) => {

    return (
        <section className={`full-page-content-section`} key={props.content.id}>
            <div className={'container'}>
                <h1>{props.content.title}</h1>
                {props.content.body && renderRichText(props.content.body)}
            </div>
        </section>
    )
}

export default FullPageContentSection;

